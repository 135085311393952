@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'https://static.pw.live/fonts/reddit/font.css';

img {
    display: unset;
    vertical-align: unset;
}

body {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.vertical-align {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.main-container {
    width: 1200px;
    margin: 0 auto;

    @media (max-width: 1536px) {
        width: 1074px;
    }

    @media (max-width: 1367px) {
        width: 940px;
    }

    @media (min-width: 1025px) and (max-width: 1366px) {
        width: 820px;
    }

    @media (max-width: 1024px) {
        width: 770px;
    }

    @media (max-width: 940px) {
        width: 100%;
    }
}

.heading-1 {
    font-size: 40px !important;

    @media (max-width: 992px) {
        font-size: 35px !important;
    }

    @media (max-width: 768px) {
        font-size: 33px !important;
    }

    @media (max-width: 576px) {
        font-size: 35px !important;
    }
}

.heading-2 {
    font-size: 30px !important;

    @media (max-width: 992px) {
        font-size: 27px !important;
    }

    @media (max-width: 768px) {
        font-size: 25px !important;
    }

    @media (max-width: 576px) {
        font-size: 23px !important;
    }
}

.heading-3 {
    font-size: 24px !important;

    @media (max-width: 992px) {
        font-size: 22px !important;
    }

    @media (max-width: 768px) {
        font-size: 19px !important;
    }

    @media (max-width: 576px) {
        font-size: 16px !important;
    }
}

.heading-4 {
    font-size: 20px !important;

    @media (max-width: 992px) {
        font-size: 18px !important;
    }

    @media (max-width: 768px) {
        font-size: 16px !important;
    }

    @media (max-width: 576px) {
        font-size: 15px !important;
    }
}

.heading-5 {
    font-size: 18px !important;

    @media (max-width: 992px) {
        font-size: 16px !important;
    }

    @media (max-width: 768px) {
        font-size: 15px !important;
    }

    @media (max-width: 576px) {
        font-size: 13px !important;
    }
}

.body-1 {
    font-size: 16px !important;

    @media (max-width: 992px) {
        font-size: 14px !important;
    }

    @media (max-width: 768px) {
        font-size: 13px !important;
    }

    @media (max-width: 576px) {
        font-size: 12px !important;
    }
}

.body-2 {
    font-size: 14px !important;

    @media (max-width: 992px) {
        font-size: 13px !important;
    }

    @media (max-width: 768px) {
        font-size: 12px !important;
    }

    @media (max-width: 576px) {
        font-size: 11px !important;
    }
}

.caption-1 {
    font-size: 12px !important;

    @media (max-width: 992px) {
        font-size: 11px !important;
    }

    @media (max-width: 576px) {
        font-size: 10px !important;
    }
}

.caption-2 {
    font-size: 10px !important;

    @media (max-width: 992px) {
        font-size: 9px !important;
    }

    @media (max-width: 576px) {
        font-size: 8px !important;
    }
}

// Classes for All the Font weights
.bold {
    font-weight: 700 !important;
}

.semibold {
    font-weight: 600 !important;
}

.medium {
    font-weight: 500 !important;
}

.black {
    font-weight: 900 !important;
}

.light {
    font-weight: 300 !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.delete_ques_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 514px;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
        }
    }
}

.upload-docs-css {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 514px;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
        }
    }
}

.fetch-verify-dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 700px;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
        }
    }
}

.edit_ques_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 90vw;
        height: 86vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
        }
    }
}

.verify_alert_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 514px;
        height: 198px;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
        }
    }
}

.add_rule_set_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 100vw;
        min-height: 52vh;
        /* overflow-x: clip; */

        @media (max-width: 1024px) {
            min-height: 82vh;
            min-width: 82vw;
        }

        @media (min-height: 1366px) {
            min-height: 37vh;
        }

        @media (max-width: 820px) {
            min-width: 80vw;
            min-height: 50vh;
        }
        @media (max-width: 768px) {
            min-height: 55vh;
        }

        @media (max-width: 425px) {
            height: 80vh;
            padding-bottom: 50px;
        }
    }
}

.create_ruleset_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 100vw;
        height: 86vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 86vh;
        }
    }
}

.custom-dialog-container .mat-dialog-container {
    width: 280px;
    padding: 10px;
    overflow: hidden;
    @media (max-width: 425px) {
        padding: 5px;
    }
}

.create_question_paper_dialog {
    max-width: 94vw !important;
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 1000px;

        @media (max-width: 768px) {
            width: 80vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 98vh;
            //height: 86vh;
        }
    }
}

.filter_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 1023px;
        height: 86vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 86vh;
        }
    }
}

.preview-pdf-dialog {
    max-width: 92vw !important;
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 1023px;
        height: 86vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 86vh;
        }
    }
}

.create_custom_pattern_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        padding: 0;
        width: 1023px;
        height: 86vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 86vh;
        }
    }
}

.manage_rule_set_dialog {
    max-width: 90vw !important;
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 1115px;
        height: 86vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 86vh;
        }
    }
}

.preview_dialog {
    @media (max-width: 576px) {
        max-width: 90vw !important;
    }

    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 1000px;
        //height: 82vh;
        padding: 10px;

        @media (max-width: 1024px) {
            width: 75vw;
            height: 40vh;
        }

        @media (max-width: 768px) {
            width: 75vw;
            height: 40vh;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: calc(100vh - 64vh);
            padding: 5px;
        }
    }
}

.tag_language_selection_dialog {
    max-width: 90vw !important;
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 670px;
        height: 66vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
            height: 86vh;
        }
    }
}

.mat-spinner circle {
    stroke: #594bd9 !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    opacity: 0 !important; /*click effect color change*/
    background-color: #5a4bda !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #5a4bda !important; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #5a4bda !important; /*outer ring color change*/
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #5a4bda;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #5a4bda;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-disabled .mat-checkbox-background {
    background-color: #cdcdcd !important;
}

.mat-button-wrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: #5a4bda;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #5a4bda;
}

::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html,
body {
    height: 100%;
}

/*.upload-docs-css .mat-dialog-container {
    border-radius: 20px;
}*/
.mat-slide-toggle-bar {
    width: 33px !important;
    height: 18px !important;
    flex-shrink: 0;
    border-radius: 8px;
}

.mat-slide-toggle-thumb-container {
    position: absolute;
    z-index: 1;
    width: 7px !important;
    height: 7px !important;
    top: 1px !important;
    left: 0;
}

.mat-slide-toggle-thumb {
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
}

/* custom style for tiny mce editor */

.tox-tinymce {
    border-radius: 12px !important;
}

/* custom style for solution video url link */

.video_url_link {
    color: #5a4bda;
}

/*custom style for video player */

.video-js button {
    color: #5a4bda !important;
}

.vjs-duration-display {
    color: #5a4bda !important;
}

.vjs-current-time-display {
    color: #5a4bda !important;
}

/*overriding elements tailwind style */

ul,
menu {
    list-style: disc !important;
    margin: 10px 0 !important;
    padding: 0 0 0 20px !important;
}

ol {
    list-style: decimal !important;
    margin: 10px 0 !important;
    padding: 0 0 0 20px !important;
}

/*css for word-break*/

.break-words {
    word-break: break-word;
}

.question_type_section {
    .mat-radio-button {
        background: #feffff 0% 0% no-repeat padding-box;
        border: 2px solid #dcdcdc;
        padding: 10px;
        border-radius: 10px;
        margin-right: 12px;
        min-width: 100px;
        margin-bottom: 6px;
        @media (max-width: 576px) {
            min-width: auto;
        }
    }
    .mat-radio-checked {
        border: 2px solid #5a4bda;
    }
    .radio-btn-text {
        color: #606160;
    }
}

.answer_option_section {
    .add-icon {
        //font-size: 14px;
        color: #5a4bda;
    }
    .option-description {
        border: 1px solid #dcdcdc;
        border-radius: 6px;
        padding: 10px;
    }

    .option-text {
        color: #888888;
    }

    .option-selection {
        background: #feffff 0% 0% no-repeat padding-box;
        border: 2px solid #5a4bda;
        border-radius: 10px;
        padding: 16px;
    }

    .option-box {
        color: #606160;
    }
}

.concept-list-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .line {
        flex: 1;
    }

    .text {
        padding-left: 10px;
        padding-right: 10px;
    }

    .add-concept-text {
        color: #5a4bda;
    }

    .add-circle-icon {
        color: #5a4bda;
    }
}

.errorBorder {
    border: 2px solid red !important;
}

.clr-dark-grey {
    color: #888888;
}

.clr-error {
    color: #fd2010;
}

.mt-12 {
    margin-top: 3rem;
}

.disabled_option {
    background: #d3dade54 !important;
    opacity: 0.3;
}

.attach-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 17px #2326311f;
    border-radius: 6px;
    cursor: pointer;

    @media (max-width: 375px) {
        margin-left: 8px;
    }
}

.text-heading {
    @media (min-width: 375px) {
        margin-right: 8px;
    }
}

.disabled_marking_colour {
    background: #d3dade54 !important;
    cursor: no-drop;
}

.add-mat-icon {
    color: #5a4bda;
    cursor: pointer;
}

table {
    max-width: 400px;
    border: 1px solid #000000;
    colgroup {
        col {
            border: 1px solid #000000;
        }
    }
    tr {
        border: 1px solid #000000;
        th {
            border: 1px solid #000000;
        }
        td {
            border: 1px solid #000000;
        }
    }
}

.mat-calendar-content {
    table {
        max-width: unset;
        border: unset;
        thead {
            tr {
                border: unset;
                th {
                    border: unset;
                }
                td {
                    border: unset;
                }
            }
        }
        tbody {
            tr {
                border: unset;
                th {
                    border: unset;
                }
                td {
                    border: unset;
                }
            }
        }
    }
}

.create_question_paper_choice_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 450px;
        height: 270px;
    }
}

.preview_ques_dialog {
    .mat-dialog-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        width: 90vw;
        height: 75vh;

        @media (max-width: 768px) {
            width: 75vw;
        }

        @media (max-width: 576px) {
            width: 97vw;
        }
    }
}

.wrs_tickContainer {
    display: none;
}

/*error notification class for wiris mathtype plugins*/
.tox .tox-notification--in {
    opacity: 0 !important;
}
